<template>
<div class="d-flex flex-row d-flex justify-content-between" style="width:100%" >
    <div class="p-2">
    <QRCodeVue3
          :value="data"
        />
    </div>
<div class="p-2">




   
<p class="alert alert-success" @click="download()">To access the   full functionality   Download MyPaaa app</p>


<br><button class="btn btn-warning float-right" @click="download()"><i class="fa fa-download" aria-hidden="true"></i>Download App</button>


</div> 
</div>
</template>
<script>
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: "download",
   props: ['data','section'],
   components: {
   QRCodeVue3
  },
  data() {
   
  },
  methods: {
    download(){
    window.location.href = 'http://onelink.to/pgyk4h'
    }
  }
}

</script>